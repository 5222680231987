// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic'

export const components = {
  about_hero: dynamic(() => import('./AboutHero')),
  about_intro: dynamic(() => import('./AboutIntro')),
  about_quote: dynamic(() => import('./AboutQuote')),
  all_blogs: dynamic(() => import('./AllBlogs')),
  all_endorsements: dynamic(() => import('./AllEndorsements')),
  all_events: dynamic(() => import('./AllEvents')),
  all_members: dynamic(() => import('./AllMembers')),
  all_past_events: dynamic(() => import('./AllPastEvents')),
  big_image: dynamic(() => import('./BigImage')),
  blog_post_hero: dynamic(() => import('./BlogPostHero')),
  blog_post_rich_text: dynamic(() => import('./BlogPostRichText')),
  blog_post_video: dynamic(() => import('./BlogPostVideo')),
  blog_testimonial: dynamic(() => import('./BlogTestimonial')),
  blogs_category: dynamic(() => import('./BlogsCategory')),
  call_to_action: dynamic(() => import('./CallToAction')),
  complete_payment: dynamic(() => import('./CompletePayment')),
  contact_form: dynamic(() => import('./ContactForm')),
  contact_our_branches: dynamic(() => import('./ContactOurBranches')),
  donate_now: dynamic(() => import('./DonateNow')),
  donate_other_modes: dynamic(() => import('./DonateOtherModes')),
  donate_pay_now: dynamic(() => import('./DonatePayNow')),
  donation_thankyou: dynamic(() => import('./DonationThankyou')),
  endorsements_component: dynamic(() => import('./EndorsementsComponent')),
  event_detail_abpout: dynamic(() => import('./EventDetailAbpout')),
  event_details_cta: dynamic(() => import('./EventDetailsCta')),
  event_details_hero: dynamic(() => import('./EventDetailsHero')),
  event_details_video: dynamic(() => import('./EventDetailsVideo')),
  event_videos: dynamic(() => import('./EventVideos')),
  events_detail_past_events: dynamic(() => import('./EventsDetailPastEvents')),
  featured_blogs: dynamic(() => import('./FeaturedBlogs')),
  featured_blogs_in_project: dynamic(() => import('./FeaturedBlogsInProject')),
  featured_members: dynamic(() => import('./FeaturedMembers')),
  featured_work: dynamic(() => import('./FeaturedWork')),
  hero_background_image: dynamic(() => import('./HeroBackgroundImage')),
  homepage_hero: dynamic(() => import('./HomepageHero')),
  image_gallery: dynamic(() => import('./ImageGallery')),
  imapct_statement: dynamic(() => import('./ImapctStatement')),
  our_partners: dynamic(() => import('./OurPartners')),
  past_event: dynamic(() => import('./PastEvent')),
  project_gallery: dynamic(() => import('./ProjectGallery')),
  quote_testimonials: dynamic(() => import('./QuoteTestimonials')),
  search: dynamic(() => import('./Search')),
  short_hero: dynamic(() => import('./ShortHero')),
  subscribe_ebook: dynamic(() => import('./SubscribeEbook')),
  testimonial_background_image: dynamic(
    () => import('./TestimonialBackgroundImage')
  ),
  text_blue_background: dynamic(() => import('./TextBlueBackground')),
  text_with_image: dynamic(() => import('./TextWithImage')),
  text_with_video: dynamic(() => import('./TextWithVideo')),
  video_details: dynamic(() => import('./VideoDetails')),
  who_we_are: dynamic(() => import('./WhoWeAre')),
}
