import Head from '../components/Head'
import { SliceZone } from '@prismicio/react'
import { getLanguage } from '../lib/getLanguage'
import { createClient } from '../prismicio'
import { getHomepage } from '../utils/prismicLoaders/page'
import { getLayout } from '../utils/prismicLoaders/layout'
import { components } from '../slices'
import { useEffect } from 'react'
import { useLayout } from '../utils/layout-context'
import { AdditionalDataProvider } from '../utils/additional-data-context'
import { fetchAdditionalData } from '../utils/fetch-additional-data'
import Header from 'next/head'

const Homepage = ({ page, layout, additionalData }) => {
  const { data } = page
  const { setHeaderColor } = useLayout()

  useEffect(() => {
    if (data) setHeaderColor(data.headercolor)
  }, [data])

  return (
    <>
      <Header>
        <meta
          className="elastic"
          name="content_type"
          content={layout.data.search_page_label}
        />
      </Header>
      <Head data={page.data} canonical={'/homepage'} />
      <AdditionalDataProvider value={additionalData}>
        <SliceZone slices={page.data.body} components={components} />
      </AdditionalDataProvider>
    </>
  )
}

export async function getStaticProps({ previewData, locale }) {
  const client = createClient({ previewData })
  const languageObject = getLanguage(locale)
  const language = await languageObject.prismicLanguage.toLowerCase()

  const queryParams = {
    fetchLinks: [
      'project_category.icon',
      'project_category.title',
      'project.featured_image',
      'project.title',
      'project.project_category',
      'blog_post.title',
      'blog_post.featured_image',
      'blog_post.date',
      'blog_post.blog_topics',
      'blog_post.blog_topics.topic_name',
      'person.name',
      'person.title',
      'person.person_type',
      'person.portrait_image',
      'person.full_bio',
      'endorsement.endorser_name',
      'endorsement.endorser_title',
      'endorsement.endorsement',
      'endorsement.endorser_profile_image',
    ],
    lang: language,
  }
  const page = await getHomepage(client, queryParams)
  const layout = await getLayout(client, {
    lang: language,
  })

  const additionalData = await fetchAdditionalData(
    client,
    page.data.body,
    'homepage',
    language
  )

  return {
    props: {
      page,
      additionalData,
      layout,
      language,
    },
  }
}

export default Homepage
