import React from 'react'
import Header from 'next/head'
import { RichText } from 'prismic-reactjs'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

interface IHeadProps {
  data: any
  canonical: string
  type?: string
}

const Head = ({ data, canonical, type }: IHeadProps) => {
  const siteUrl = getConfig().publicRuntimeConfig.siteUrl
  const router = useRouter()

  const canonicalUrl =
    canonical === '/homepage'
      ? `${siteUrl}/${router.locale}`
      : `${siteUrl}/${router.locale}${canonical}`

  let ogImageUrl = null

  if (data.og_image && data.og_image.url) {
    if (type === 'blog_detail' && data.meta_title && router.locale !== 'he') {
      const titleText = encodeURIComponent(RichText.asText(data.meta_title))
      const imageUrl = encodeURIComponent(`${data.og_image.url}&width=1200`)
      ogImageUrl = `https://ogcdn.net/c7e90d5e-fd03-43f6-97ae-a3356807bcfc/v2/${imageUrl}/${titleText}/og.png`
    } else {
      ogImageUrl = data.og_image.url
    }
  }

  return (
    <Header>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d6a560" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="twitter:card" content="summary_large_image" />

      {data.meta_title && <title>{RichText.asText(data.meta_title)}</title>}
      {!data.meta_title && <title>VFI</title>}
      {data.meta_description && (
        <meta
          name="description"
          content={RichText.asText(data.meta_description)}
        />
      )}
      {data.meta_title && (
        <>
          <meta
            property="og:title"
            content={RichText.asText(data.meta_title)}
          />
          <meta
            property="twitter:title"
            content={RichText.asText(data.meta_title)}
          />
        </>
      )}
      {data.meta_description && (
        <>
          <meta
            property="og:description"
            content={RichText.asText(data.meta_description)}
          />
          <meta
            property="twitter:description"
            content={RichText.asText(data.meta_description)}
          />
        </>
      )}
      {ogImageUrl && (
        <>
          <meta property="og:image" content={ogImageUrl} />
          <meta property="twitter:image" content={ogImageUrl} />
        </>
      )}

      {data.robots_index && (
        <meta name="robots" content={data.robots_index ? 'all' : 'noindex'} />
      )}
      <meta property="og:url" content={canonicalUrl} />
      <meta className="elastic" name="image" content={data.og_image.url} />
      <meta className="elastic" name="image_alt" content={data.og_image.alt} />
      <link rel="canonical" href={canonicalUrl} />
    </Header>
  )
}

export default Head
